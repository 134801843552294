<template>
  <el-card id="realReport">
    <header>
      <div class="search">
        <div class="left">
          <div
            class="left-wrap"
            style="margin-right: 10px"
            v-if="role == '客服'"
          >
            <span>查询公司</span>
            <el-select
              v-model="firmValue"
              placeholder="选择查询公司"
              size="small"
              @change="getFirmVal"
            >
              <el-option
                v-for="item in firmList"
                :key="item.id"
                :label="item.realname"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap">
            <span>自定义查询时间</span>
            <el-date-picker
              v-model="customTime"
              type="daterange"
              range-separator="-"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              @calendar-change="calendar"
              unlink-panels
              :disabled-date="disableddate"
              size="small"
            ></el-date-picker>
          </div>
          <el-button
            type="primary"
            icon="el-icon-search"
            style="margin-left: 10px"
            @click="customTimeSearch(1)"
            size="small"
            >线路查询</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-search"
            style="margin-left: 10px"
            @click="customTimeSearch(2)"
            size="small"
            >直呼查询</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="reset()"
            size="small"
            >重置</el-button
          >
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="day"
            size="small"
            class="searchBtn"
            >日报表</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="week"
            size="small"
            class="reset"
            >周报表</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="month"
            size="small"
            class="reset"
            >月报表</el-button
          >
        </div>
      </div>
    </header>
    <el-divider></el-divider>
    <main v-loading="loading" element-loading-text="请稍等，数据加载中……">
      <div class="time-report">
        <span>{{ tableData[0].day }}</span>
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        height="600"
        show-summary
      >
        <el-table-column
          prop="name"
          label="员工名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="seconds"
          label="累加通话秒数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="minute"
          label="累加通话分钟数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="moneyMinute"
          label="计费分钟数"
          sortable
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sumCount"
          label="总呼叫次数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="count"
          label="接通次数"
          sortable
          align="center"
        ></el-table-column>
        <el-table-column
          prop="count_sumCount"
          label="接通率"
          sortable
          align="center"
        ></el-table-column>

        <el-table-column
          prop="avg"
          label="平均通话时长"
          align="center"
        ></el-table-column>
      </el-table>
    </main>
    <!-- :visible.sync -->
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  tableCountTable,
  tableWeektableAll,
  tableMonthtableAll,
  tableDaytableAll,
  countTableDirect,
} from "@/api/table";
import { sysDepartList } from "@/api/sys_depart";
import { userOperatorsFindlist } from "@/api/user";
export default {
  data() {
    return {
      showTitleText: false,
      roleName: null,
      firmVal: null,
      firmValue: null,
      firmList: [],
      role: null,
      value: "",
      customTime: null,
      tableData: [{ day: "" }],
      realname: null,
      id: null,
      loading: true,
    };
  },
  created() {
    this.id = localStorage.getItem("id");
    this.realname = localStorage.getItem("realname");
    this.roleName = localStorage.getItem("roleName");
    this.role = localStorage.getItem("role");
    this.day();
    if (this.role == "客服") {
      this.operatorsList();
    }
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  },
  methods: {
    // 获取下拉框选中值
    getFirmVal(val) {
      this.firmVal = val;
      this.day1();
    },
    // 客服查公司
    operatorsList() {
      let params = {
        page: 1,
        size: 499,
        parentId: 0,
      };
      let res = userOperatorsFindlist(params);
      res.then((res) => {
        this.firmList = res.data.data;
      });
    },
    // 重置
    reset() {
      this.firmValue = null;
      this.customTime = null;
      this.day();
      if (this.role == "客服") {
        this.operatorsList();
      }
      // this.firmValue = null;
    },
    disableddate(time) {
      return (
        time.getTime() > Date.now() - 1000 * 60 * 60 * 24 * 0 ||
        time.getTime() < Date.now() - 1000 * 60 * 60 * 24 * 45
      );
    },
    calendar(arr) {
      this.value = arr[0];
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    getDate(date) {
      this.form.day = dayjs(date).format("YYYY-MM-DD");
    },
    customTimeSearch(callType) {
      if (callType) {
        this.callType = callType;
      }
      let searchTime = localStorage.getItem("searchTime") * 1;
      if (searchTime) {
        let timesZdy1 = Date.parse(new Date());
        if (timesZdy1 - searchTime >= 30000) {
          this.customTimeSearch1();
          Message.success("30秒后可再次获取");
          let timesZdy = Date.parse(new Date());
          localStorage.setItem("searchTime", timesZdy);
        } else {
          this.loading = false;
          Message.warning("时间未满30秒，请稍等");
        }
      } else {
        this.customTimeSearch1();
        Message.success("30秒后可再次获取");
        let timesZdy = Date.parse(new Date());
        localStorage.setItem("searchTime", timesZdy);
      }
    },
    customTimeSearch1() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 2000);
      console.log("获取时间", this.customTime);
      let sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.customTime[0]);
      let end = this.getYMDHMS(
        "YYYY-mm-dd HH:MM:SS",
        this.customTime[1] - 1000
      );
      let params = {
        userId: this.id,
        sta,
        end,
      };
      if (this.firmVal) {
        params.userId = this.firmVal;
      }
      let res = {};
      if (this.callType == 1) {
        res = tableCountTable(params);
      } else if (this.callType == 2) {
        res = countTableDirect(params);
      }
      res.then((res) => {
        this.tableData = [];
        this.tableData = res.data.data;
        console.log("res", res.data.data);
      });
    },
    // 时间限制-今
    day() {
      let dayTime = localStorage.getItem("dayTime") * 1;
      if (dayTime) {
        let timesDay1 = Date.parse(new Date());
        if (timesDay1 - dayTime >= 30000) {
          this.day1();
          Message.warning("30秒后可再次获取");
          let timesDay = Date.parse(new Date());
          localStorage.setItem("dayTime", timesDay);
        } else {
          Message.warning("时间未满30秒，请稍等");
        }
      } else {
        this.day1();
        Message.warning("30秒后可再次获取");
        let timesDay = Date.parse(new Date());
        localStorage.setItem("dayTime", timesDay);
      }
    },
    // 今
    async day1() {
      this.loading = true;
      let params = {};
      if (this.firmVal) {
        params = {
          userId: this.firmVal,
        };
      } else {
        params = {
          userId: this.id,
        };
      }
      let res = await tableDaytableAll(params);
      this.tableData = [];
      this.tableData = res.data.data;
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    // 时间限制-周
    week() {
      let weekTime = localStorage.getItem("weekTime") * 1;
      if (weekTime) {
        let timesWeek1 = Date.parse(new Date());
        if (timesWeek1 - weekTime >= 30000) {
          this.week1();
          Message.warning("30秒后可再次获取");
          let timesWeek = Date.parse(new Date());
          localStorage.setItem("weekTime", timesWeek);
        } else {
          Message.warning("时间未满30秒，请稍等");
        }
      } else {
        this.week1();
        Message.warning("30秒后可再次获取");
        let timesWeek = Date.parse(new Date());
        localStorage.setItem("weekTime", timesWeek);
      }
    },
    // 周
    week1() {
      this.loading = true;
      let params = {};
      if (this.firmVal) {
        params = {
          userId: this.firmVal,
        };
      } else {
        params = {
          userId: this.id,
        };
      }
      let res = tableWeektableAll(params);
      res.then((res) => {
        this.tableData = [];
        this.tableData = res.data.data;
      });
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    // 时间限制-月
    month() {
      let monthTime = localStorage.getItem("monthTime") * 1;
      if (monthTime) {
        let timesMonth1 = Date.parse(new Date());
        if (timesMonth1 - monthTime >= 30000) {
          this.month1();
          Message.warning("30秒后可再次获取");
          let timesMonth = Date.parse(new Date());
          localStorage.setItem("monthTime", timesMonth);
        } else {
          Message.warning("时间未满30秒，请稍等");
        }
      } else {
        this.month1();
        Message.warning("30秒后可再次获取");
        let timesMonth = Date.parse(new Date());
        localStorage.setItem("monthTime", timesMonth);
      }
    },
    // 月
    month1() {
      this.loading = true;
      let params = {};
      if (this.firmVal) {
        params = {
          userId: this.firmVal,
        };
      } else {
        params = {
          userId: this.id,
        };
      }
      let res = tableMonthtableAll(params);
      res.then((res) => {
        this.tableData = [];
        this.tableData = res.data.data;
      });
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    getDepart() {
      let params = {
        topId: this.id,
      };
      let res = sysDepartList(params);
      res.then((res) => {
        this.options = res.data.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,
.el-button--primary {
  color: #fff;
  background-color: #00b76c;
  border-color: #00b76c;
  &:hover {
    opacity: 0.7;
  }
}
#realReport {
  header {
    h1 {
      font-weight: normal;
      > span {
        font-size: 18px;
        color: #606369;
      }
    }
    .search {
      display: flex;
      margin-top: 20px;
      align-items: center;
      justify-content: space-between;
      // flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            // width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        // margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>