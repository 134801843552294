/**
 * table
 * 报表
 */

import request from '@/utils/request'

// 个人通话数据
export const tableUserSelectShell = (data) => {
    return request({
        method: 'post',
        url: '/table/userSelectShell',
        data
    })
}
// 公司所有人通话记录
export const tableSelectShell = (data) => {
    return request({
        method: 'post',
        url: '/table/selectShell',
        data
    })
}
// 日
export const tableDay = (data) => {
    return request({
        method: 'post',
        url: '/table/day',
        data
    })
}
// 月
export const tableMonth = (data) => {
    return request({
        method: 'post',
        url: '/table/month',
        data
    })
}
// 
export const tableCountTable = (data) => {
    return request({
        method: 'post',
        url: '/table/count-table',
        data
    })
}
// 
export const tableDaytableAll = (data) => {
    return request({
        method: 'post',
        url: '/table/daytableAll',
        data
    })
}
// 
export const tableWeektableAll = (data) => {
    return request({
        method: 'post',
        url: '/table/weektableAll',
        data
    })
}
// 
export const tableMonthtableAll = (data) => {
    return request({
        method: 'post',
        url: '/table/monthtableAll',
        data
    })
}

// 今周月查询
export const tableTimeAll = (url, data) => {
    return request({
        method: 'post',
        url: '/table/' + url,
        data
    })
}

// 电话统计(直呼)
export const countTableDirect = (data) => {
	return request({
		url: "/table/count-table/direct",
		method: "post",
		data
	});
};
