/**
 * user
 * 用户
 */

import request from '@/utils/request'

// 展示查找
export const userFindlist = (data) => {
    return request({
        method: 'post',
        url: '/user/findlist',
        data
    })
}
// 展示
export const userList = (data) => {
    return request({
        method: 'post',
        url: '/user/list',
        data
    })
}
// 展示1
export const userList1 = (data) => {
    return request({
        method: 'post',
        url: '/user/list1',
        data
    })
}
// 编辑
export const userUpdate = (data) => {
    return request({
        method: 'post',
        url: '/user/update',
        data
    })
}
// 添加
export const userAdd = (data) => {
    return request({
        method: 'post',
        url: '/user/add',
        data
    })
}
// 
export const userGetUserLogin = (data) => {
    return request({
        method: 'post',
        url: '/user/getUserLogin',
        data
    })
}
// 
export const userFindAppidbytype = (data) => {
    return request({
        method: 'post',
        url: '/user/find-appidbytype',
        data
    })
}
// 
export const userOperatorsFindlist = (data) => {
    return request({
        method: 'post',
        url: '/user/operators-findlist',
        data
    })
}